export const useCookieStore = defineStore("cookies", () => {
    const { gtag, initialize } = useGtag();

    const necessaryCookies = ["i18n_redirected", "kadewe_loyalty_staging_session", "xsrf-token"];
    const isSet = ref(false);

    const cookieStatus = ref({
        i18n_redirected: true,
        kadewe_loyalty_staging_session: true,
        "xsrf-token": true,
        google_analytics: null,
    });

    const loadCookies = () => {
        // Load the cookie state from localStorage and return if they were already set
        const cookieState = localStorage.getItem("cookies");
        if (cookieState !== null) {
            Object.assign(cookieStatus.value, JSON.parse(cookieState));
            return true;
        }

        return false;
    };

    const updateCookie = (cookieName: string, value: boolean): boolean => {
        if (necessaryCookies.includes(cookieName)) {
            console.warn(`Cannot change the status of necessary cookie ${cookieName}`);
            return false;
        }

        // Update the cookie state but don't save it to localStorage yet
        cookieStatus.value[cookieName] = value;
        return true;
    };

    const saveCookies = () => {
        localStorage.setItem("cookies", JSON.stringify(cookieStatus.value));
        isSet.value = true;

        initScripts();
    };

    const acceptAllCookies = () => {
        for (const cookieName in cookieStatus.value) {
            updateCookie(cookieName, true);
        }

        saveCookies();
    };

    const initScripts = () => {
        if (cookieStatus.value.google_analytics) {
            console.debug("Initializing analytics");

            initialize();

            gtag("consent", "update", {
                analytics_storage: "granted",
            });
        } else {
            gtag("consent", "update", {
                ad_storage: "denied",
                analytics_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
            });
        }
    };

    isSet.value = loadCookies();
    initScripts();

    return {
        cookieStatus,
        isSet,
        updateCookie,
        saveCookies,
        acceptAllCookies,
    };
});
