<template>
    <Html
        :lang="head.htmlAttrs.lang"
        :dir="head.htmlAttrs.dir"
    >
        <Head>
            <Title>{{ title }}</Title>
            <template
                v-for="link in head.link"
                :key="link.id"
            >
                <Link
                    :id="link.id"
                    :rel="link.rel"
                    :href="link.href"
                    :hreflang="link.hreflang"
                />
            </template>
            <template
                v-for="meta in head.meta"
                :key="meta.id"
            >
                <Meta
                    :id="meta.id"
                    :property="meta.property"
                    :content="meta.content"
                />
            </template>
        </Head>
        <Body>
            <div class="flex flex-col min-h-screen">
                <layout-header />
                <main class="flex flex-grow">
                    <slot />
                </main>
                <slot name="footer" />
                <cookie-banner />
            </div>
        </Body>
    </Html>
</template>

<script setup lang="ts">
const route = useRoute();
const { t } = useI18n();
const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: "id",
    addSeoAttributes: true,
});
const title = computed(() => t((route.meta.title ?? "layouts.title") as string));
</script>
