<template>
    <div
        v-for="cookie in cookies"
        :key="cookie.id"
        class="flex gap-x-4 my-4 items-start"
    >
        <form-toggle
            :label="cookie.id"
            :name="cookie.id"
            :model-value="cookieStatus[cookie.id]"
            @update:model-value="updateCookie(cookie.id, $event)"
        />

        <p class="text-sm mt-0.5">{{ cookie.title }} - {{ cookie.purpose }}</p>
    </div>
</template>

<script setup lang="ts">
defineProps<{
    cookies: Cookie[];
}>();

const cookieStore = useCookieStore();
const { cookieStatus } = storeToRefs(cookieStore);

const updateCookie = (id: string, checked: boolean) => {
    cookieStore.updateCookie(id, checked);
};
</script>
