<template>
    <LayoutModal
        ref="cookieModal"
        size="sm:max-w-6xl text-left"
        :center="false"
        :closable="false"
    >
        <template #default>
            <h3
                class="mt-0 text-lg uppercase font-semibold text-left"
                v-text="$t('cookie-banner.title')"
            />
            <p v-text="$t('cookie-banner.description')" />
            <p class="mt-4 text-sm">
                <nuxt-link
                    :to="localePath('/cms/privacy-policy')"
                    class="underline"
                    >{{ $t("cookie-banner.privacy-policy") }}</nuxt-link
                >

                <nuxt-link
                    :to="localePath('/cms/imprint')"
                    class="underline ml-16"
                    >{{ $t("cookie-banner.imprint") }}</nuxt-link
                >
            </p>

            <tab-group
                v-if="settingsVisible"
                class="mt-8 max-w-auto"
                tab-classes="!mb-8"
                tab-content-classes="!max-w-full !w-full !mx-0"
                :tabs-bold="false"
                :tabs-left="true"
                :tabs="[
                    {
                        id: 'necessary',
                        title: $t('cookie-banner.tab-settings'),
                        selected: activeTab === 'settings',
                    },
                    {
                        id: 'optional',
                        title: $t('cookie-banner.tab-cookies'),
                        selected: activeTab === 'details',
                    },
                ]"
                @change-tab="activeTab = activeTab === 'settings' ? 'details' : 'settings'"
            >
                <template v-if="activeTab === 'settings'">
                    <div class="w-full flex flex-col sm:flex-row justify-start">
                        <div>
                            <h4
                                class="font-semibold text-base"
                                v-text="$t('cookie-banner.settings.essentials.title')"
                            />
                            <p
                                class="text-sm"
                                v-text="$t('cookie-banner.settings.essentials.description')"
                            />

                            <cookie-settings :cookies="cookies.necessary" />
                        </div>

                        <div>
                            <h4
                                class="font-semibold text-base"
                                v-text="$t('cookie-banner.settings.statistics.title')"
                            />
                            <p
                                class="text-sm"
                                v-text="$t('cookie-banner.settings.statistics.description')"
                            />

                            <cookie-settings :cookies="cookies.statistic" />
                        </div>
                    </div>
                </template>
                <template v-else-if="activeTab === 'details'">
                    <cookie-list :cookies="cookies" />
                </template>
            </tab-group>
        </template>
        <template #actions>
            <div class="flex flex-wrap justify-center gap-4 md:gap-8">
                <form-button
                    v-if="settingsVisible"
                    type="default"
                    :classes="['sm:w-80']"
                    @click="saveCookies()"
                >
                    {{ $t("cookie-banner.save") }}
                </form-button>
                <form-button
                    v-else
                    type="default"
                    :classes="['sm:w-80']"
                    @click="settingsVisible = true"
                >
                    {{ $t("cookie-banner.show-settings") }}
                </form-button>

                <form-button
                    type="primary"
                    :classes="['sm:w-80']"
                    @click="acceptAllCookies()"
                >
                    {{ $t("cookie-banner.accept-all") }}
                </form-button>
            </div>
        </template>
    </LayoutModal>
</template>
<script lang="ts" setup>
const { t } = useI18n();

const cookies = ref<{
    necessary: Cookie[];
    statistic: Cookie[];
}>({
    necessary: [
        {
            id: "i18n_redirected",
            title: t("cookie-banner.cookies.i18n_redirected.title"),
            purpose: t("cookie-banner.cookies.i18n_redirected.purpose"),
            provider: t("cookie-banner.cookies.i18n_redirected.provider"),
            names: ["i18n_redirected"],
            expires: t("cookie-banner.cookies.i18n_redirected.expires"),
            checked: true,
            privacy_policy: "/cms/privacy-policy",
        },
        {
            id: "kadewe_loyalty_staging_session",
            title: t("cookie-banner.cookies.kadewe_loyalty_staging_session.title"),
            purpose: t("cookie-banner.cookies.kadewe_loyalty_staging_session.purpose"),
            provider: t("cookie-banner.cookies.kadewe_loyalty_staging_session.provider"),
            names: ["kadewe_loyalty_staging_session"],
            expires: t("cookie-banner.cookies.kadewe_loyalty_staging_session.expires"),
            checked: true,
            privacy_policy: "/cms/privacy-policy",
        },
        {
            id: "xsrf-token",
            title: t("cookie-banner.cookies.xsrf-token.title"),
            purpose: t("cookie-banner.cookies.xsrf-token.purpose"),
            provider: t("cookie-banner.cookies.xsrf-token.provider"),
            names: ["xsrf-token"],
            expires: t("cookie-banner.cookies.xsrf-token.expires"),
            checked: true,
            privacy_policy: "/cms/privacy-policy",
        },
    ],
    statistic: [
        {
            id: "google_analytics",
            title: t("cookie-banner.cookies.google_analytics.title"),
            purpose: t("cookie-banner.cookies.google_analytics.purpose"),
            provider: t("cookie-banner.cookies.google_analytics.provider"),
            names: ["_ga", "_gat", "_gid"],
            expires: t("cookie-banner.cookies.google_analytics.expires"),
            checked: true,
            privacy_policy: "https://policies.google.com/technologies/partner-sites?hl=de",
        },
    ],
});

const cookieModal = ref<InstanceType<typeof LayoutModal> | null>(null);
const route = useRoute();
const onPrivacyPolicy = ref(false);
const activeTab = ref<"settings" | "details">("settings");
const cookieStore = useCookieStore();
const { isSet } = storeToRefs(cookieStore);
const { $listen } = useNuxtApp();
const settingsVisible = ref(false);

const updatePrivacyPolicyStatus = () => {
    if (route.params && (route.params.slug === "privacy-policy" || route.params.slug === "imprint")) {
        onPrivacyPolicy.value = true;
        return;
    }

    onPrivacyPolicy.value = false;
};

watch(
    () => route.params,
    () => {
        updatePrivacyPolicyStatus();

        if (onPrivacyPolicy.value) {
            cookieModal.value?.close();
        }
    },
);

watch(onPrivacyPolicy, async () => {
    if (!onPrivacyPolicy.value && !isSet.value) {
        cookieModal.value?.open();
    }
});

onMounted(() => {
    updatePrivacyPolicyStatus();

    if (!onPrivacyPolicy.value && !isSet.value) {
        console.debug(onPrivacyPolicy, isSet.value);
        cookieModal.value?.open();
    }

    $listen("cookie-banner:open", () => {
        cookieModal.value?.open();
    });
});

const saveCookies = () => {
    cookieStore.saveCookies();
    cookieModal.value?.close();
};

const acceptAllCookies = () => {
    cookieStore.acceptAllCookies();
    cookieModal.value?.close();
};
</script>
